import './googlemaps.scss';

import * as dompack from 'dompack';

dompack.onDomReady(() => {
  $('.emb-googlemaps').each(function() {
    let container = $(this).find('.emb-googlemaps__mapcontainer').get(0);

    let centerData = $(this).data('center');

    let center = { lat: parseFloat(centerData.split(',')[0]),
                   lng: parseFloat(centerData.split(',')[1]),
                 };

    let map = new google.maps.Map(container, {
      center: center,
      zoom: 12,
      disableDoubleClickZoom: true,
      scrollwheel: false,
    });

    let markers = $(this).data('markers');

    for (const markerInfo of markers) {
      var infowindow = new google.maps.InfoWindow({
        content: markerInfo.description,
        maxWidth: 300,
      });

      let latlng = new google.maps.LatLng(center.lat, center.lng);

      let marker = new google.maps.Marker({
        position: latlng,
        map,
      });

      marker.addListener('click', function() {
        infowindow.open(map, marker);
      });
    }
  });
});

import './slideshow.scss';

import '../../webdesigns/shared/web/swiper.min.css';

import * as dompack from "dompack";

// import Swiper from 'swiper'; // can't get Swiper4 to work this way :-(

dompack.onDomReady(() => {
  $('.nope-header-slideshow__overlay').addClass('show');
  $('.page-header--with-overlay').addClass('show');
  $('.nope-header-slideshow').each(function () {
    let $container = $(this).find('.swiper-container').first();

    let numSlides = $container.find('.swiper-slide').length;
    if (numSlides < 1)
      return;

    if (numSlides < 2)
      $container.find('.swiper-pagination, .swiper-button-prev, .swiper-button-next').remove();

    let speed = parseInt($(this).data('animationspeed'));
    if (!speed)
      speed = 500;

    let autoplay = parseInt($(this).data('autoplayspeed'));
    if (!autoplay)
      autoplay = 4000;

    let swiper = new Swiper($container, {
      loop: numSlides > 1,
      speed: speed,
      autoplay: {
        delay: autoplay,
      },
      allowSlidePrev: numSlides > 1,
      allowSlideNext: numSlides > 1,
      grabCursor: numSlides > 1,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });

    if ($(this).attr('data-pauseonhover')) {
      $container.hover(function() {
        this.swiper.stopAutoplay();
      }, function() {
        this.swiper.startAutoplay();
      });
    }
  });
});

import './sportlink_subscribe.scss';

// WebHare
import * as dompack from 'dompack';
import { RPCFormBase, registerHandler } from '@mod-publisher/js/forms';

// vendor
// import $ from 'jquery';

class SubscribeForm extends RPCFormBase {
  constructor(node) {
    super(node);

    this.setup();

    if (dompack.debugflags['debug'])
      this.invokeRPC('debugprefill');
  }

  async setup() {
    let captchaString = await this.invokeRPC('GetCaptchaValue');
    $('#sportlink-subscribe-captcha-image').attr('src', `data:image/png;base64,${captchaString}`);
  }

  // errors: array of { message, metadata, name }
  onSubmitFailed(errors, result) {
    console.error(result);
    if (result.unknown_error === true)
      alert("Er ging iets mis bij het verzenden van het formulier. Probeer het nog eens of neem contact met ons op als het probleem zich blijft voordoen.");
  }
}

registerHandler('nope_sportlink:sportlinksubscribe', node => new SubscribeForm(node));
